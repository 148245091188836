import ServiceApi from '../services/service.api';

const baseServiceUrl = `/booking`;

const ApiBooking = {
  appointmentReschedule: (
    patient_guid = '',
    appointment_id,
    dateTimeSlot = '',
    clinician_guid = '',
    appointment_type = ''
  ) => {
    const url = `${baseServiceUrl}/appointment/reschedule`;
    const body = {
      user_guid: patient_guid, // Selected Patient GUID
      appt_id: parseInt(appointment_id),
      date: dateTimeSlot,
      clin_user_guid: clinician_guid,
      call_type_code: `${appointment_type}`.toUpperCase() //AUDIO - VIDEO
    };
    console.debug('RE-SCHEDULE APPOINTMENT PRE-BODY:', body);
    return new Promise((resolve, reject) => {
      ServiceApi.post(url, body)
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.status === 423) {
            resolve(error);
          } else reject(error);
        });
    });
  },
  appointmentSlots: (
    params
  ): Promise<{
    appointment_slots: {
      dom: string;
      dow: string;
      slots: {
        clin_count: number;
        slot_datetime: string;
        slot_end_datetime: string;
        slot_end_datetime2: string;
        slot_lengh_mins: number;
      }[];
    }[];
  }> => {
    const url = `${baseServiceUrl}/appointment/slots/${ServiceApi.userGuid}`;
    return new Promise((resolve, reject) => {
      ServiceApi.get(url, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  confirmAppointment: (clinician_guid = '', case_id, dateTimeSlot = '', appointment_type = '', type = '') => {
    const url = `${baseServiceUrl}/appointment`;
    const body = {
      user_guid: ServiceApi.userGuid,
      clin_guid: clinician_guid,
      case_id: parseInt(case_id),
      date: dateTimeSlot,
      type,
      call_type: `${appointment_type}`.toUpperCase() //AUDIO - VIDEO
    };
    console.debug('STEP2: NEW APPOINTMENT PR-BODY', body);
    return ServiceApi.post(url, body);
  },
  appointmentCancel: (appointment_id, reason = '', systemType = '') => {
    const url = `${baseServiceUrl}/appointment/cancel`;
    const body = {
      user_guid: ServiceApi.userGuid,
      appt_id: `${appointment_id}`,
      reason
    };
    console.debug('CANCEL-APPOINTMENT PRE-BODY', body);
    return ServiceApi.post(url, body);
  },
  getAppointmentList: (params = {}, systemType = '') => {
    const url = `${baseServiceUrl}/appointments/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  postCaseReview: (params) => {
    const url = `${baseServiceUrl}/case/review`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...params
    };
    return ServiceApi.post(url, body);
  },
  authorizeExistingCase: (
    // Change here
    case_id = '',
    // appt_count = null,
    appt_type_code = '',
    user_subtype_code = null
  ) => {
    const url = `${baseServiceUrl}/appointment/auth`;
    const body = {
      user_guid: ServiceApi.userGuid,
      case_id,
      // appt_count,
      appt_type_code,
      user_subtype_code
    };
    return ServiceApi.put(url, body);
  },

  removeAuthorisation: (patientGuid, caseId, authCancelCode, availability) => {
    const url = `${baseServiceUrl}/appointment/auth/remove`;
    const body = {
      user_guid: patientGuid,
      case_id: caseId,
      auth_cancel_code: authCancelCode,
      availability
    };

    return ServiceApi.put(url, body);
  },

  getF2FClinicians: ({ patientGuid, clinicianSpecCat, caseId, apptId, fromDate, typeCode, postcode }) => {
    const url = `/f2f-consultants/?date=${fromDate}&patient_guid=${patientGuid}&clinicianSpecCat=${clinicianSpecCat}&caseId=${caseId}&ref_appt_id=${apptId}&patient_clin_subtype_code=${typeCode}&postcode=${postcode}`;

    return new Promise((resolve, reject) => {
      ServiceApi.get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  bookF2FClinician: (postBody) => {
    const url = '/f2f-consultants';

    return ServiceApi.post(url, postBody);
  }
};

export default ApiBooking;
