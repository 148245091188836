import * as z from 'zod';

const AddressSchema = z.object({
  postcode: z.string(),
  addline1: z.string(),
  addline2: z.string().optional(),
  addline3: z.string().optional(),
  addline4: z.string().optional(),
  region: z.string().optional(),
  country: z.string().optional()
});

export const LabTestSchema = z.object({
  labs: z.array(
    z.object({
      appt_id: z.number(),
      case_id: z.number(),
      // a.k.a. the case ref!
      client_case_id: z.string(),
      ext_order_id: z.string().nullable(),
      full_name: z.string(),
      geninv_status_desc: z.string().nullable(),
      lab_test_status_code: z.string().nullable(),
      lab_tests: z.array(
        z.object({
          lab_test_id: z.number(),
          service_cat_code: z.string(),
          service_cat_desc: z.string(),
          service_type_code: z.string(),
          service_type_desc: z.string(),
          randox_code: z.string().optional()
        })
      ),
      lucy_id: z.string(),
      randox_status_id: z.string().nullable(),
      user_guid: z.string(),
      patient_address: AddressSchema,
      order_address: AddressSchema.partial().nullable(),
      order_route_desc: z.string().nullable(),
      order_route_code: z.string().nullable(),
      home_kit_order: z.union([z.literal(0), z.literal(1)]),
      in_person_order: z.union([z.literal(0), z.literal(1)]),
      tracking_id: z.string().nullable()
    })
  )
});

export type LabTests = z.infer<typeof LabTestSchema>;
export type LabTest = LabTests['labs'][number];
