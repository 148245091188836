import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { TiWarningOutline } from 'react-icons/ti';
import { withRouter } from 'react-router-dom';
import { Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row, UncontrolledAlert } from 'reactstrap';
import qs from 'vl-common/src/lib/query-string';
import { Api as LocalAip } from 'vl-common/src/api/index';
import ConfigLink from '../../../core/configs/config.link';
import { LoadingButton } from 'vl-common/src/components/LoadingButton';
import AccountLayout from './Components/AccountLayout';
import { cognitoPasswordRE } from '@lib/cognitoPasswordRE';

interface FormValues {
  password: string;
  verificationCode: string;
  rePassword: string;
}

function ResetYourPassword({ history, location }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, handleSubmit, watch, errors, reset } = useForm<FormValues>();
  const [errorApi, setErrorApi] = useState('');
  const [loading, setLoading] = useState(false);
  const [isNormalResetPasswordFlow, setIsNormalResetPasswordFlow] = useState(true);
  const { state } = location;

  if (!state || !state.email) {
    history.push(ConfigLink.ForgetPassword);
  }

  useEffect(() => {
    setTimeout(() => {
      reset({});
    }, 250);

    const local_state = qs.parse(location.search);
    if (local_state.normalPsw) {
      setIsNormalResetPasswordFlow(true);
    } else {
      setIsNormalResetPasswordFlow(false); // password expire mode
    }

    return function unMount() {};
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      setErrorApi('');
      await LocalAip.user.resetPassword({
        email: state.email,
        code: data.verificationCode,
        password: data.password
      });
      reset({});
      history.push(ConfigLink.AccountLogin);
    } catch (error) {
      setErrorApi(error.response.data.errorMessage);
    }
    setLoading(false);
  };

  useEffect(() => {
    return function unMount() {};
  }, []);

  return (
    <AccountLayout>
      <Row>
        <Col className="text-left mx-auto px-md-4 vl-reset-your-pass-page" sm="12" md={{ size: 5 }}>
          {isNormalResetPasswordFlow && (
            <p className="mt-5 account-page-label">
              Reset <span>Password</span>
            </p>
          )}
          {!isNormalResetPasswordFlow && (
            <p className="mt-5 account-page-label">
              Password <span>Expired</span>
            </p>
          )}

          <p className="account-text">
            <i>
              <span>Email address:</span> <strong>{state ? state.email : ''}</strong>
            </i>
          </p>

          <p className="mt-3 account-text">
            {!isNormalResetPasswordFlow && (
              <span>
                In order to help your account remain secure, your password has been expired and we require you to choose
                a new one,&nbsp;
              </span>
            )}
            Use the code that has been sent to your email to set a new password.
            <div style={{ fontSize: '12px', marginTop: '5px' }}>
              <p>Please note, your new password cannot be the same as your old one, and must have:</p>
              <p>
                <ul>
                  <li>
                    <strong>Minimum Length</strong>, which must be 8 characters but fewer than 99
                  </li>
                  <li>
                    <strong>Require Numbers</strong>, at least one number
                  </li>
                  <li>
                    <strong>Require Uppercase letters</strong>, at least one uppercase letter
                  </li>
                  <li>
                    <strong>Require Lowercase letters</strong>, at least one lowercase letter
                  </li>
                  <li>
                    <strong>Require a Special Character</strong>, from this set: ^ $ * . [ ] {} ( ) ? &quot; ! @ # %
                    &amp; / \ , &lt; &gt; &#39; : ; | _ ~ ` + &#39;`{' '}
                    <em>
                      Note: the plus &quot;+&quot; and minus &quot;-&quot; signs do not meet special character
                      requirements
                    </em>
                  </li>
                </ul>
              </p>
            </div>
          </p>

          <p className="mt-2">
            <UncontrolledAlert className="account-login-alert" color="danger" isOpen={errorApi !== ''}>
              <TiWarningOutline size="1.5em" /> {errorApi}
            </UncontrolledAlert>
          </p>

          <Form onSubmit={handleSubmit(onSubmit)} className="mt-5 text-left validation">
            <input type="hidden" value="something" />
            <FormGroup>
              <Label className="account-text" for="email">
                Enter Verification Code<span className="vl-orange">*</span>
              </Label>
              <Input
                type="text"
                name="verificationCode"
                id="verificationCode"
                placeholder="Verification Code"
                autoComplete="off"
                innerRef={register({
                  required: {
                    value: true,
                    message: 'This field is required'
                  }
                })}
              />
              {errors.verificationCode && <p>{errors.verificationCode.message}</p>}
            </FormGroup>
            <FormGroup>
              <Label className="account-text" for="password">
                New Password<span className="vl-orange">*</span>
              </Label>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  placeholder=""
                  autoComplete="off"
                  innerRef={register({
                    required: {
                      value: true,
                      message: 'This field is required'
                    },
                    pattern: {
                      value: cognitoPasswordRE,
                      message:
                        'This password must contain 8 characters and include at least one capital letter and one number'
                    }
                  })}
                />
                <InputGroupText className="">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                  >
                    <FiEye className={showPassword ? 'd-none' : ''} />
                    <FiEyeOff className={showPassword ? '' : 'd-none'} />
                  </a>
                </InputGroupText>
              </InputGroup>
              <i className="vl-fs-small">Hint: Click the “eye” to display the password text as you type</i>
              {errors.password && <p>{errors.password.message}</p>}
            </FormGroup>
            <FormGroup>
              <Label className="account-text" for="rePassword">
                Confirm New Password<span className="vl-orange">*</span>
              </Label>
              <InputGroup>
                <Input
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="rePassword"
                  id="rePassword"
                  placeholder=""
                  autoComplete="off"
                  innerRef={register({
                    validate: (value) => {
                      return value === watch('password') || 'Passwords do not match';
                    }
                  })}
                />
                <InputGroupText>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                    className=""
                  >
                    <FiEye className={showConfirmPassword ? 'd-none' : ''} />
                    <FiEyeOff className={showConfirmPassword ? '' : 'd-none'} />
                  </a>
                </InputGroupText>
              </InputGroup>
              {errors.rePassword && <p>{errors.rePassword.message}</p>}
            </FormGroup>
            <LoadingButton
              className="mt-5 py-2 btn-block vl-bg-orange"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </LoadingButton>
          </Form>
        </Col>
      </Row>
    </AccountLayout>
  );
}

export default withRouter(ResetYourPassword);
