import format_raw from 'date-fns/format';
// todo - limit the locales to ones we support
import * as locales from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';
import { getRuntimeConfig } from '../hooks/Runtime';

const CUSTOM = '(custom)';
export const HBSFormatToDateFnsFormat = {
  'Date @ Time - 03/01/2022 @ 8:57 AM': 'P @ p',
  'Date @ Time (long localised) - Friday, March 3rd, 2022 @ 8:57 AM': 'PPPP @ p',
  'Date @ Time (short, no year, localised) - Fri, Mar 3rd @ 8:57 AM': 'E, LLL do @ p',
  'Date & Time (ISO 1806) - 2022-03-02T17:19:33.173Z': CUSTOM,
  'Date & Time (short ISO 1806) - 2022-03-02T17:19Z': CUSTOM,

  'Long Date with day-of-week - Thursday, March 3rd, 2022': 'PPPP',
  'Date only - 03/01/2022': 'P',

  'Date only (localised) - 03/02/2022': 'P',
  'Date only (short year) - 03/01/22': CUSTOM,
  'Date only (short) - 03/01/22': 'p',
  'Date only (ISO) - 2022-03-11': 'yyyy-MM-dd',
  'Date only (ISO) with abbreviated Month (ISO) - 29/Sep/2024': 'dd/MMM/yyyy',
  'Date only (localised - dashes) - 11-03-2022': 'dd-MM-yyyy',
  'Month and Year (localised)': 'P',

  '24 hour time - 09:47': 'HH:mm',
  '12 hour time with AM/PM (padded hours)': 'hh:mm a',
  '12 hour time with AM/PM (un-padded hours)': 'h:mm a'
};

const DateFnsLocaleFromBrowserLocale = new Map<string, Locale>();

function findDateFnsLocaleDefinition(browserLocale: string) {
  if (!browserLocale) {
    return locales.enGB;
  }

  if (DateFnsLocaleFromBrowserLocale.has(browserLocale)) {
    return DateFnsLocaleFromBrowserLocale.get(browserLocale);
  }

  const [language, ...tokens] = browserLocale.split('-');
  const region = tokens[tokens.length - 1];
  const dateFnsLocale = locales[`${language}${region}`] || locales[language];

  if (!dateFnsLocale) {
    return null;
  }

  DateFnsLocaleFromBrowserLocale.set(browserLocale, dateFnsLocale);

  return dateFnsLocale;
}

// format a date using date-fns: https://date-fns.org/v2.28.0/docs/format#description
export function format(
  date: Date,
  format: keyof typeof HBSFormatToDateFnsFormat | string,
  locale?: string,
  timezone?: string
) {
  const formatter = HBSFormatToDateFnsFormat[format] || format;
  const { SERVER_LOCALE } = getRuntimeConfig();
  const useBrowserLocale = SERVER_LOCALE !== 'true';

  if (!date) {
    return '';
  }

  const options = Intl.DateTimeFormat().resolvedOptions();

  if (useBrowserLocale) {
    locale = locale || options.locale;
    timezone = timezone || options.timeZone;
  } else if (globalThis?.GlobalStore?.auth) {
    const { lang_code, timezone: tz } = globalThis.GlobalStore.auth;

    locale = locale || (lang_code === 'en' ? 'en-GB' : lang_code);
    timezone = timezone || tz;
  }

  if (!locale) locale = options.locale;
  if (!timezone) timezone = options.timeZone;

  if (formatter === CUSTOM) {
    switch (format) {
      case 'Date only (short year) - 03/01/22': {
        const options: Intl.DateTimeFormatOptions = {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        };
        return new Intl.DateTimeFormat(locale, options).format(date);
      }
      case 'Date & Time (ISO 1806) - 2022-03-02T17:19:33.173Z':
        return date?.toISOString() || '';

      case 'Date & Time (short ISO 1806) - 2022-03-02T17:19Z':
        if (!date) return '';
        return `${date.toISOString().split(/:\d\d[.]/)[0]}Z`;

      default:
        throw Error(`Unrecognised custom date format: "${format}"`);
    }
  }

  const localeDefinition = findDateFnsLocaleDefinition(locale) || findDateFnsLocaleDefinition('en-GB');
  if (!localeDefinition) {
    throw Error(`Could not find locales "${locale}" or "en-GB"`);
  }

  // todo - if the timezone is not recognised it is quietly ignored
  return format_raw(utcToZonedTime(date, timezone), formatter, {
    locale: localeDefinition
  });
}

export default format;
