import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRuntimeConfig } from '../hooks/Runtime';

const UIVersionLabel = () => {
  const { APP_VERSION } = useRuntimeConfig();
  const { data } = useQuery({
    queryKey: ['UIVersionLabel'],
    queryFn: async () => {
      const res = await fetch('/VERSION.txt');

      return [res.headers.get('Last-Modified'), await res.text()];
    }
  });
  const [modifiedDateString, version] = data || [];
  const modified = new Date(modifiedDateString || 0);

  return (
    <>
      <span title={modifiedDateString || ''}>
        {/* version from VERSION.txt contains the string with all info we need to show, no need to prepend anything*/}
        {version || (
          <>
            {/* The REACT_APP_UI_VERSION is usually just the commit id, but for story branches it is the branch name */}
            {/* with the epoch time and commit hash. Strip those out */}
            {APP_VERSION}:
            <span data-chromatic="ignore">
              {Math.floor(modified.getTime() / 1000)}:{process.env.REACT_APP_UI_VERSION?.split('.')[1]}
            </span>
          </>
        )}
      </span>
      &nbsp;
      <a target="_blank" rel="noreferrer" href="https://www.hbsuk.co.uk">
        &copy;HBS
      </a>
      UK 2020-{new Date().getFullYear().toString().slice(-2)}
    </>
  );
};

export default UIVersionLabel;
