import React, { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Col, Row, Alert, Form, Input } from 'ALIASED-antd';
import qs from 'vl-common/src/lib/query-string';
import ConfigLink from '../../../core/configs/config.link';
import AccountLayout from './Components/AccountLayout';
import PrivacyPolicyModal from './Components/PrivacyPolicyModal';
import TermsAndConditionsModal from './Components/TermsAndConditionsModal';

import useApi from 'vl-common/src/hooks/useApi';
import { useRuntimeConfig } from 'vl-common/src/hooks/Runtime';
import { UnstyledButton } from 'vl-common/src/components/UnstyledButton';

function ForgetPassword({ history }) {
  const { Api } = useApi();
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [errorApi, setErrorApi] = useState('');
  const [loading, setLoading] = useState(false);
  const { APP_CLIENT_ID } = useRuntimeConfig();

  let progress = true;
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  let isInProgress = async function () {
    let i = 10;
    do {
      i--;
      console.debug('progress ...');
      await sleep(200);
      if (i <= 0) {
        progress = false;
        break;
      }
    } while (progress);
  };

  const onSubmit = async (data) => {
    try {
      setErrorApi('');
      setLoading(true);

      //await

      let breakTheFlow = false;

      Api.user
        .forgotPassword({
          clientId: APP_CLIENT_ID,
          username: data.email
        })
        .then((res) => {
          if (res) {
            if (res.data)
              if (res.data.CodeDeliveryDetails)
                if (res.data.CodeDeliveryDetails.DeliveryMedium.toLowerCase() === 'temp') {
                  breakTheFlow = true;
                  history.push({
                    pathname: ConfigLink.wrongFlow
                  });
                  return;
                }

            progress = false;
          }
        })
        .catch((er) => {
          console.error('rv1->', er);
          progress = false;
        });
      await isInProgress();

      if (breakTheFlow) return;

      let searchString = qs.stringify({
        normalPsw: true // normal reset password flow, not expire one
      });
      history.push({
        pathname: ConfigLink.ResetYourPassword,
        search: searchString,
        state: { email: data.email }
      });
    } catch (err) {
      setErrorApi(err.message);
    }
    setLoading(false);
  };

  return (
    <AccountLayout>
      <Row>
        <Col className="text-left" sm="12">
          <UnstyledButton
            className="vl-back-button-holder vs-fs-medium font-weight-bolder d-block"
            onClick={() => {
              history.push(ConfigLink.AccountLogin);
            }}
          >
            <IoIosArrowBack className="icon" /> Back
          </UnstyledButton>
        </Col>
      </Row>
      <Row>
        <Col className="text-left mx-auto px-md-4" sm="12" md={{ size: 5 }}>
          <p className="mt-5 account-page-label">
            Forgot <span>Password</span>
          </p>

          <p className="account-text">
            Enter the email address of your Virtual Lucy account and we’ll email you a code to reset your password.
          </p>

          <div className="mt-2">{errorApi && <Alert type="error" message={errorApi} />}</div>

          <Form onFinish={onSubmit} layout="vertical" className="mt-5 text-left validation">
            <Form.Item
              label="Your Email Address"
              name="email"
              validateTrigger="onSubmit"
              rules={[
                {
                  type: 'email',
                  message: 'Please enter your e-mail address'
                },
                {
                  required: true,
                  message: 'Please enter your e-mail address'
                }
              ]}
              className="login-form-item"
            >
              <Input autoComplete="off" className="login-input" data-testid="forgot-email-input" />
            </Form.Item>
            <Button
              className="mt-5 py-2 btn-block vl-bg-orange"
              type="submit"
              loading={loading}
              disabled={loading} //|| !isFormValid()
            >
              Submit
            </Button>
            <div className="mt-5 text-center">
              <br />
              <br />
            </div>
          </Form>
        </Col>
      </Row>
      <TermsAndConditionsModal
        close={() => {
          setShowTermsAndConditionsModal(false);
        }}
        show={showTermsAndConditionsModal}
      />
      <PrivacyPolicyModal
        close={() => {
          setShowPrivacyPolicyModal(false);
        }}
        show={showPrivacyPolicyModal}
      />
    </AccountLayout>
  );
}

export default withRouter(ForgetPassword);
