import React, { forwardRef, type DetailedHTMLProps } from 'react';
import styled from '@emotion/styled';

const Button = styled.button(
  {
    all: 'unset',
    display: 'inline-block',
    padding: 0,
    border: 'none',
    background: 'none',
    font: 'inherit',
    color: 'inherit',
    textAlign: 'inherit',
    lineHeight: 'inherit'
  },
  (props) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer'
  })
);

export const UnstyledButton = forwardRef<
  HTMLButtonElement,
  DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>(({ type = 'button', ...rest }, ref) => <Button type={type} {...rest} ref={ref} />);
UnstyledButton.displayName = 'UnstyledButton';
