import formatDate from './formatDate';

const isDateString = (v: any) => typeof v === 'string' && /^\d{4}-\d\d-\d\dT[\d:]{5,}([.]\d\d\d)?Z$/.test(v);

function transformValue(v: any): any {
  if (isDateString(v)) return new Date(v);
  if (v && typeof v === 'object') return transformNestedDateValues(v);
  return v;
}

export function transformNestedDateValues(o: any): any {
  if (o && typeof o === 'object') {
    if (Array.isArray(o)) return o.map(transformValue);
    return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, transformValue(v)]));
  }
  return o;
}

function encodeValue(v: any) {
  if (v && typeof v === 'object' && v instanceof Date) {
    return formatDate(v, 'Date & Time (short ISO 1806) - 2022-03-02T17:19Z');
  }
  return v;
}

export function encodeNestedDateValues(o: any) {
  if (o && typeof o === 'object') {
    if (Array.isArray(o)) return o.map(encodeValue);
    return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, encodeValue(v)]));
  }
  return o;
}
