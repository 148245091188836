import ServiceApi from '../services/service.api';
import { LabTestSchema } from '../schemas/shared/lab';
import { safeParse } from '../schemas/helpers/safeParse';

const baseServiceUrl = `/labs`;

const ApiLabs = {
  getLabs: async (query: any) => {
    const params = {
      page: 1,
      status: '',
      sort: 'desc',
      ...query
    };
    return ServiceApi.get(`${baseServiceUrl}/labs/${(ServiceApi as Record<string, unknown>).userGuid}`, {
      params
    }).then((res) => safeParse(res.data, LabTestSchema));
  }
};

export default ApiLabs;
