// style

import './init';
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/sass/app/app.scss';
import Auth from 'aws-amplify';

const MAX_MINS_INACTIVITY = 60;

declare global {
  interface Window {
    lastUseApi: number;
    checkExpireSession: (cancel: boolean) => boolean;
    restApiTimer: () => void;
  }
}

window.lastUseApi = Date.now();
window.checkExpireSession = (cancelUpdate = false) => {
  // HAR testing
  if (Auth.authenticatedUser) return true;

  try {
    const diffMinutes = (Date.now() - window.lastUseApi) / 1000 / 60;
    if (diffMinutes >= MAX_MINS_INACTIVITY) {
      console.debug(`Session expired at ${diffMinutes} minutes`);
      return false;
    }
  } catch (error) {
    console.debug('checkExpireSession error: ', error);
  }

  if (!cancelUpdate) {
    window.lastUseApi = Date.now();
  }

  return true;
};

window.restApiTimer = () => {
  window.lastUseApi = Date.now();
};

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
